.dataResult {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;

  background-color: white;

  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
}

.dataResult:focus-within {
  border: 1px solid #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
